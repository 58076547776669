/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
//Variaveis de cores do sistemas
$cor-primary-tributei: #00aa44;
$cor-secondary-tributei: #0e76a8;
$cor-font-tributei: #111827;

//Variaveis de cores do sistemas
svg {
  cursor: pointer;
}

.nav-lead {

  .header-navbar,
  .main-menu {
    top: 45px !important;
  }

  .main-menu {
    height: 95% !important;
  }

  .content-wrapper {
    margin-top: 3rem;
  }
}

.modal-open {
  overflow: hidden !important;
  padding-right: 0 !important;
}

.disabled-button {
  cursor: not-allowed;
}

//cores padrões do sistemas
.background-gradiente {
  background: linear-gradient(118deg, $cor-primary-tributei 7.55%, #00aa88 91.23%);
  // border: 0;
}

.background-azul {
  background: $cor-secondary-tributei !important;
  border: 0;
}

.background-azul:focus {
  background: $cor-secondary-tributei !important;
}

.background-azul:active {
  background: $cor-secondary-tributei !important;
}

//cores padrões do sistemas

//espaçamento via gap (o elemento precisa esta com flex)
.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-5 {
  gap: 5rem;
}

.gap-7 {
  gap: 7rem !important;
}

.gap-8 {
  gap: 8rem;
}

.gap-10 {
  gap: 10rem;
}

//espaçamento via gap (o elemento precisa esta com flex)
.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

//Espaçamento padrão para o titulo de cards (1 dedo do Jefferson)
.card-titulo-margin {
  margin: 1rem 0 2rem 0;
  font-size: 1.4rem;
}

.pagina-titulo-margin {
  margin: 1rem 0 2rem 0;
}

//Espaçamento padrão para o titulo de cards (1 dedo do Jefferson)

//Padronização dos botões
.font-button {
  font-weight: 500;
  font-size: 1.1rem;
}

//Padronização dos botões

//font das tabelas
.table {
  th {
    font-size: 0.8rem;
    vertical-align: middle !important;
    line-height: 1rem;
  }

  td {
    font-size: 0.9rem;
  }

  tbody {
    tr {
      transition: all .2s;
    }

    tr:hover {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 #ebe9f1;

    }

  }
}

.details-tr {
  background: #F1F1F1 !important;
  margin-top: 0;
  padding: 0;
  border: 0 !important;
  animation: fadein 1s;
  border-bottom: #ebe9f1 !important;

  th {
    padding: 0.2rem 1.2rem;
    border: 0;
  }

  tr:hover {
    transform: translateY(0px) !important;
    box-shadow: 0 3px 10px 0 #F1F1F1
  }
}

//select global
.container-button-select {
  background: var(--bs-white);
  height: 2.7rem;
  width: 15rem;
  color: #000000;
  text-align: left;
  padding-left: 0.8rem;
  font-weight: 500;
  border-radius: 0.5rem;
}

//font das tabelas
.loginTributei {
  background: linear-gradient(45deg, $cor-primary-tributei 7.55%, #00aa88 91.23%);
  border-color: $cor-primary-tributei !important;
}

.loginTributei:active {
  background-color: $cor-primary-tributei !important;
  border-color: $cor-primary-tributei !important;
}

.loginTributei:focus {
  background-color: $cor-primary-tributei !important;
  border-color: $cor-primary-tributei !important;
}

.iconTributei {
  width: 4rem !important;
}

h1 {
  color: $cor-font-tributei;
  font-weight: 500 !important;
}

h1.menuItens {
  font-size: 1rem;
  color: $cor-font-tributei;
  font-weight: 600 !important;
}

h2.logo-text {
  font-size: 1.45rem !important;
  font-weight: 700 !important;
}

h4 {
  color: $cor-font-tributei;
}

label {
  color: $cor-font-tributei !important;
  font-weight: 600;
  font-size: 1.1rem !important;
}

.titulo-card {
  font-size: 1.4rem;
  font-weight: 500;
}

// Menu lateral css
.menuIcon {
  color: $cor-font-tributei;
}

.main-menu.menu-shadow {
  box-shadow: none;
}

.overflow-none {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.vh-95 {
  height: 100vh !important;
}

[dir] {
  .vertical-layout.vertical-menu-modern.menu-collapsed {
    .main-menu.menu-light {
      .navigation {
        li.nav-item.has-sub.sidebar-group-active {
          margin-top: 0.5rem;
        }

        li.nav-item.has-sub.sidebar-group-active>ul>div>li.nav-item.has-sub.sidebar-group-active>a {
          color: #fff !important;
          font-size: 1rem !important;
          font-weight: 500;
          width: 12rem;
          margin-left: 2rem;
        }

        li.sidebar-group-active>a {
          background: linear-gradient(118deg, $cor-primary-tributei 7.55%, #00aa88 91.23%);

          h1 {
            color: #fff;

            span {
              background-color: #fff !important;
            }
          }

          .menuIcon {
            color: #fff;
          }

          ul {
            div {
              li.nav-item.has-sub.open.sidebar-group-active>a {
                background-color: $cor-font-tributei;
              }
            }
          }
        }

        li.sidebar-group-active ul {
          div {
            li {
              a {
                background-color: #fff;
              }
            }
          }
        }

        li.active {
          a {
            background: linear-gradient(118deg, $cor-primary-tributei 7.55%, #00aa88 91.23%);
          }
        }
      }
    }

    .main-menu:not(.expanded) {
      .navigation {
        li.nav-item:not(.has-sub) {
          a[href="/"] {
            display: none !important;
          }
        }

        li.active {
          a {
            background: linear-gradient(118deg,
                #00aa44 7.55%,
                #00aa88 91.23%) !important;
          }
        }
      }
    }
  }
}

.nav-item {
  a {
    span.menu-item {
      font-size: 1rem;
    }
  }

  a {
    h1 {
      padding-top: 0.7rem;
    }
  }
}

[dir="ltr"] .active {
  a {
    box-shadow: none !important;

    h1 {
      padding-top: 0.7rem;
      color: #fff;
      display: flex;
      align-items: center;

      span {
        background-color: #fff !important;
      }
    }
  }

  .menuIcon {
    color: #fff;
  }
}

h2 {
  color: $cor-font-tributei !important;
  font-weight: 700 !important;
}

[dir="ltr"] .main-menu.menu-light .navigation {
  li.nav-item:not(.has-sub) {
    a[href="/"] {
      display: none !important;
    }
  }

  li {
    ul {
      li {
        ul {
          a {
            padding: 10px 15px 10px 30px !important;
          }

          a:after {
            top: 100px;
            left: 0;
          }
        }
      }

      li.active {
        a {
          background: linear-gradient(118deg, $cor-primary-tributei 7.55%, #00aa88 91.23%);
        }
      }
    }
  }
}

[dir="ltr"] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub>a:after {
  top: 20px;
}

[dir="ltr"] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub.sidebar-group-active>a:after {
  top: 20px;
  color: #fff;
  background-image: url("../images/icons/arrow-left.svg");
}

//menu lateral css

.form-check-input:checked[type="checkbox"] {
  background-size: 10px;
}

//tooltip
.tooltipCustom {
  position: relative;
  // display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  min-width: 150px;
  background-color: #555;
  color: #fff;
  // text-align: center;
  border-radius: 6px;
  // padding: 5px 0;
  padding: 10px;
  position: absolute;
  z-index: 5 !important;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
  font-weight: 400 !important;
}

.tooltiptext-right {
  visibility: hidden;
  min-width: 250px;
  background-color: #555;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 5 !important;

  /* Posiciona o tooltip à direita do elemento */
  left: 150%;
  bottom: -100%;
  margin-bottom: 0;

  opacity: 0;
  transition: opacity 0.3s;
  font-weight: 400 !important;
}

.tooltiptext-right::after {
  content: "";
  position: absolute;
  top: 50%;
  /* Centraliza verticalmente */
  left: -8px;
  /* Posiciona a seta à esquerda do .tooltiptext */
  margin-top: -5px;
  /* Ajusta para a seta estar no meio verticalmente */
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


.tooltiptext-bottom {
  visibility: hidden;
  min-width: 250px;
  background-color: #555;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 5 !important;

  /* Posiciona o tooltip abaixo do elemento */
  top: 150%;
  left: 50%;
  transform: translateX(-50%);

  opacity: 0;
  transition: opacity 0.3s;
  font-weight: 400 !important;
}

.tooltiptext-bottom::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  /* Centraliza horizontalmente */
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltipCustom:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  display: initial;
  z-index: 10 !important;
}

.tooltipCustom:hover .tooltiptext-bottom,
.tooltipCustom:hover .tooltiptext-right {
  visibility: visible;
  opacity: 1;
  display: initial;
  z-index: 10 !important;
}

//tooltip
//Responsividade

@media (max-width:800px) {
  .navbar-mobile {
    display: block !important;
    margin-bottom: 2rem !important;
  }

  .padding-1-8 {
    padding: 0.786rem 2.258rem;
  }
}


@media (max-width:800px) {
  .responsive-tables {
    overflow: auto !important;
  }
}

// .modal-body {
//   margin-right: 2rem !important;
//   margin-left: 2rem !important; 
// }



.scrollbar_custom::-webkit-scrollbar {
  height: 0.5rem;
}

.scrollbar_custom::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  border: 1px solid #999;
}

code[class*="language-"],
pre[class*="language-"] {
  color: #ccc;
  // font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"] {
  background: #2d2d2d !important;
}

[dir] pre {
  background-color: #fff !important;

  code {
    color: #111827 !important;
  }
}



@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 200px 0;
  }
}

.shimmer-element {
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 50%, #f6f7f8 100%);
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
  height: 2.5rem;
}


.produtosEan {
  .suggestions-list {
    width: 75% !important;
    max-height: 350px !important;
  }
}


.background-grey {
  background-color: #F1F1F1 !important;
}


.modal_margin_default {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.select__multi-value {
  background: linear-gradient(118deg, #00aa44 7.55%, #00aa88 91.23%);
}